const isDev = process.env.NODE_ENV === 'development';
const useGlobalDomains = false;

const IP_ADDRESS = true ? 'localhost' : '192.168.0.197';
export const WORKERS = (() => ({
  USERS_WORKER: isDev
    ? `http://${IP_ADDRESS}:8785/`
    : 'https://worker-users.greetle.workers.dev',
}))();

export const highServiceURL = (() => {
  return !isDev
    ? 'https://api.high.greetai.co'
    : useGlobalDomains
    ? 'https://local-high.greetai.co'
    : `http://${IP_ADDRESS}:5300`;
})();

export const mediaServerSocketURL = !isDev
  ? 'wss://mediaserver.greetai.co'
  : useGlobalDomains
  ? 'https://local-mediaserver.greetai.co'
  : `ws://localhost:9040`;

export const messagingServerURL = (() => {
  return !isDev ? 'https://messaging.greetai.co' : `http://${IP_ADDRESS}:9090`;
})();

export const recorderServiceUrl = WORKERS.USERS_WORKER;
