import { Prosody } from '@/interfaces';

export function processBucketedSeries(
  bucketedSeries: Prosody['bucketedSeries'],
  topSeries: string[],
) {
  // Initialize an empty array to hold the final result
  let result: { time: number; [key: string]: number }[] = [];

  // Find the unique times across all series
  let uniqueTimes = new Set<number>();
  Object.keys(bucketedSeries)
    .filter((emotionLabel) => topSeries.includes(emotionLabel))
    .forEach((key) => {
      bucketedSeries[key].points.forEach((point) => {
        uniqueTimes.add(point.time);
      });
    });

  // Convert Set to Array and store in new variable
  const timeArray = Array.from(uniqueTimes);
  // For each unique time, create an object with values from each series
  timeArray.forEach((time) => {
    let timeEntry: { time: number; [key: string]: number } = { time: time };

    Object.keys(bucketedSeries).forEach((key) => {
      let point = bucketedSeries[key].points.find((p) => p.time === time);
      timeEntry[key] = point ? point.value : 0;
    });

    result.push(timeEntry);
  });

  // Also return the maxiumum time value

  let maximumTime = Math.max(...timeArray);
  return {
    result,
    maximumTime,
  };
}
