import { Room } from '@/interfaces';
import { atom, selector } from 'recoil';

export const fullscreenViewVisibility = atom({
  key: 'fullscreenViewVisibility',
  default: false,
});

export const onboardingModalState = atom({
  key: 'onboardingModalState',
  default: false,
});

export const roomErrorState = atom({
  key: 'roomErrorState',
  default: null,
});

export const roomNameState = atom({
  key: 'roomNameState',
  default: '',
});

export const debugModeState = atom({
  key: 'debugModeState',
  default: false,
});

export const debugMessagesState = atom({
  key: 'debugMessagesState',
  default: [],
});

export const spacebarPressedState = atom({
  key: 'spacebarPressedState',
  default: false,
});

export const isEmbeddedRoomState = atom({
  key: 'isEmbeddedRoomState',
  default: false,
});

export const roomContextState = atom({
  key: 'roomContextState',
  default: null,
});

export const roomStartedState = atom({
  key: 'roomStartedState',
  default: false,
});

export const roomEndedState = atom({
  key: 'roomEndedState',
  default: false,
});

export const roomUnusefulState = atom<'upcoming' | 'last-stage-finished'>({
  key: 'roomUnusefulState',
  default: null,
});

export const iceServersState = atom<any>({
  key: 'iceserversState',
  default: null,
});

export const roomStateSelector = selector({
  key: 'roomStateSelector',
  get: ({ get }) => {
    const roomStarted = get(roomStartedState);
    const roomEnded = get(roomEndedState);
    if (roomStarted) {
      return 'started';
    } else if (roomEnded) {
      return 'last-stage-finished';
    } else {
      return roomUnusefulState;
    }
  },
  set(opts, newValue) {
    if (newValue === 'last-stage-finished') {
      opts.set(roomEndedState, true);
      opts.set(roomStartedState, false);
    } else if (newValue === 'started') {
      opts.set(roomEndedState, false);
      opts.set(roomStartedState, true);
    } else {
      opts.set(roomUnusefulState, newValue);
    }
  },
});

export const roomStartTimeState = atom({
  key: 'roomStartTimeState',
  default: 0,
});

export const googleAuthOnboardingCredsState = atom<{
  uid: string;
  email: string;
  first_name: string;
  last_name: string;
  profile_image: string;
} | null>({
  key: 'googleAuthOnboardingCreds',
  default: null,
});

export const room_SSR_data = atom<Room | null>({
  key: 'room_SSR_data',
  default: null,
});
