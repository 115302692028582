import { useRecoilState, useRecoilValue } from 'recoil';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { getAuthDeferred } from '@/utils/firebase';
import { GoogleAuthProvider } from 'firebase/auth';
import {
  authUser,
  authInitializeTracker,
  authenticatingState,
} from '../state/auth';
import api from '../api';
import { Company, User, UserRoles } from '../interfaces';
import { toast } from 'react-hot-toast';
import { getDefaultURLFromUserRole } from '@/utils/user';

type Handler = {
  onSuccess: (creds: any) => void;
  onError: (error: any) => void;
};

type FieldValues = {
  email: string;
  password: string;
};

function useUser() {
  const user = useRecoilValue(authUser);
  const isInitialized = useRecoilValue(authInitializeTracker);
  const [isAuthenticating, setIsAuthenticating] =
    useRecoilState(authenticatingState);
  const [isOnboarding, setIsOnboarding] = useState(false);

  const handleAccountCreation = async ({
    inviter,
    email,
    password,
    meta,
  }: {
    inviter?: Company;
    email: string;
    password: string;
    meta: any;
  }) => {
    setIsOnboarding(true);
    if (inviter) {
      await api.createAccountByInvite(email, password, meta, inviter);
    } else {
      await api.createAccount(email, password, meta);
    }
    setIsOnboarding(false);
  };

  const createCompanyAccountWithGoogle = async ({
    name,
    email,
    first_name,
    last_name,
    uid,
    industry,
    profile_image,
    provider,
  }: {
    name: string;
    email: string;
    first_name: string;
    last_name: string;
    uid: string;
    industry: string;
    profile_image: string;
    provider: string;
  }) => {
    setIsOnboarding(true);
    await api.createCompanyWithUser(
      {
        name,
        email,
        first_name,
        last_name,
        account_type: 'company_admin',
        industry,
        profile_image,
        provider,
      },
      uid,
    );
    setIsOnboarding(false);
    window.location.href = getDefaultURLFromUserRole(UserRoles.COMPANY_ADMIN);
  };

  const createCompanyAccount = async (
    email: string,
    password: string,
    data: any,
  ) => {
    setIsOnboarding(true);
    const auth = getAuthDeferred();
    let userCredentials;
    try {
      userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
    } catch (e) {
      console.log(e);
      if (e.code === 'auth/email-already-in-use') {
        toast.error('Email already in use', {
          style: {
            background: '#333',
            color: 'white',
          },
        });
      }
      return;
    }

    const created = await api.createCompanyWithUser(
      data,
      userCredentials.user.uid,
    );

    // await sendEmailVerification(userCredentials.user);
    await login(
      { email, password },
      {
        onSuccess: () => {
          window.location.href = getDefaultURLFromUserRole(data.accountType);
        },
        onError: (e) => {
          console.log(e);
        },
      },
    );

    setIsOnboarding(false);
  };

  const login = async ({ email, password }: FieldValues, callback: Handler) => {
    const auth = getAuthDeferred();

    setIsAuthenticating(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(callback.onSuccess)
      .catch((e) => {
        callback.onError(e);
        setIsAuthenticating(false);
      });
  };

  const loginGoogle = () => {
    const auth = getAuthDeferred();
    setIsAuthenticating(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // IdP data available using getAdditionalUserInfo(result)
      })
      .catch((error) => {
        setIsAuthenticating(false);
        toast.dismiss('auth-status');
      });
  };

  const logout = async (callback: Handler) => {
    const auth = getAuthDeferred();

    auth.signOut().then(callback.onSuccess).catch(callback.onError);
  };

  return {
    user,
    createCompanyAccount,
    createCompanyAccountWithGoogle,
    handleAccountCreation,
    login,
    logout,
    loginGoogle,
    isInitialized,
    isOnboarding,
    isCompanyUser: user?.role === UserRoles.COMPANY_ADMIN,
    isAuthenticating,
    authUser: user as User,
  };
}

export default useUser;
