import { useRecoilState, useSetRecoilState } from 'recoil';
import { useEffect, useRef } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import {
  authUser,
  authInitializeTracker,
  authenticatingState,
} from './state/auth';
import { getAuthDeferred } from '@/utils/firebase';
import api from './api';
import { LoadingOverlay } from '@mantine/core';
import { toast } from 'react-hot-toast';
import { navigate } from 'vike/client/router';
import { getDefaultURLFromUserRole } from './utils/user';
import { googleAuthOnboardingCredsState } from './state/atoms';

function isGoogleAccount(user: User) {
  return user.providerData[0].providerId === 'google.com';
}

function AuthLayer({ children }: { children: React.ReactNode }) {
  const setAuthUser = useSetRecoilState(authUser);
  const [initialized, setAuthInitialize] = useRecoilState(
    authInitializeTracker,
  );
  const setGoogleAuth = useSetRecoilState(googleAuthOnboardingCredsState);

  const [authenticating, setAuthenticating] =
    useRecoilState(authenticatingState);
  useEffect(() => {
    if (authenticating) {
      toast.loading('Signing in...', {
        id: 'auth-status',
        duration: Infinity,
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          zIndex: 9999,
        },
      });
    } else {
      toast.dismiss('auth-status');
    }

    return () => {
      toast.dismiss('auth-status');
    };
  }, [authenticating]);

  async function retrieveUser(token: string) {
    const userDetails = await api.getUser(token);

    if (userDetails) {
      setAuthUser(userDetails);
    }

    return userDetails;
  }

  function handleGoogleAccount(user: User) {
    if (isGoogleAccount(user)) {
      setGoogleAuth({
        profile_image: user.photoURL,
        uid: user.uid,
        email: user.email || '',
        first_name: user.displayName?.split(' ')[0] || '',
        last_name: user.displayName?.split(' ')[1] || '',
      });
      navigate('/register');
    }
  }

  useEffect(() => {
    const unlisten = onAuthStateChanged(getAuthDeferred(), async (user) => {
      const locationPathname = window.location.pathname;
      if (user) {
        try {
          const token = await user?.getIdToken();
          const fetchedUser = await retrieveUser(token);
          if (locationPathname === '/' || locationPathname === '/register') {
            if (fetchedUser?.role) {
              await navigate(getDefaultURLFromUserRole(fetchedUser?.role));
            } else {
              await navigate('/');
            }
            setAuthInitialize(true);
          } else {
            setAuthInitialize(true);
          }
        } catch (e) {
          if (e.response.status === 404) {
            // If user is not found after register/sign in means
            // it might be that it's newly created google account and we need to register it on our backend
            handleGoogleAccount(user);
            console.error(e);
          }
          setAuthInitialize(true);
        }
      } else {
        if (locationPathname !== '/register') {
          navigate('/');
        }
        setAuthInitialize(true);
        setAuthUser(null);
      }
      setAuthenticating(false);
    });

    return () => {
      unlisten();
      setAuthInitialize(false);
      setAuthenticating(false);
    };
  }, [setAuthUser, setAuthInitialize]);

  return <>{!initialized ? <LoadingOverlay visible /> : children}</>;
}

export default AuthLayer;
