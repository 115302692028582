export default function invariant(expr: any, msg: string): asserts expr {
  if (!expr) {
    throw new Error(msg);
  }
}

export function addProtocol(url) {
  if (!url) return;
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'http://' + url;
  }
  return url;
}

export const tags = (() => {
  let title = 'GreetAI';
  let description = '';
  let keywords = '';

  title =
    'GreetAI — AI powered assessments for screening, training and evaluation.';
  description =
    'GreetAI is an AI powered assessment tool that helps you screen, train and evaluate candidates faster.';
  keywords =
    'ai screening,ats tool,applicant tracking system,candidate screening,recruitment automation,hiring software,ai recruitment,talent acquisition,resume screening,hiring process,recruitment tool,ai hiring,job application software,automated hiring,recruitment platform,ai talent screening,ai hiring tool,recruitment management,ai candidate evaluation,video interview,ai video assessment,automated interviews,interview screening,video recruitment,remote hiring,virtual interviews,interview analytics,candidate assessment,skill evaluation,behavioral analysis,communication assessment,ai interview scoring,interview automation,talent screening platform,video screening tool';

  return {
    title,
    keywords,
    description,
  };
})();
